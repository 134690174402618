<template>
    <AppPanel :location="['Clientes', 'Cargo']" subtitle="Listagem de cargos">
        <template #content>
            <AppPaginatedGrid
                ref="grid"
                :service="service"
                permission="estrutura_cargo"
                newPath="/position/new"
                editPath="/position/"
                :expander="true"
                subtitle="Cargos"
                tooltip="Cadastro Cargos"
                nomeTelaDoManual="position-list"
                origemHistoricoAcao="/customer/positions"
            >
                <template #columns>
                    <Column field="id" header="Cod." :sortable="true" sortField="customer_positions.id"></Column>
                    <Column field="cliente.name" :sortable="true" header="Cliente" sortField="cliente.name"></Column>
                    <Column field="name" :sortable="true" header="Cargo" sortField="customer_positions.name"></Column>
                    <Column field="cbo.descricao" :sortable="true" sortField="customer_positions.cbo" header="CBO"></Column>
                    <Column
                        field="gfipAposentadoriaEspecial"
                        :sortable="true"
                        sortField="customer_positions.gfipAposentadoriaEspecial"
                        header="GFIP"
                    ></Column>
                    <Column header="Codigo SOC" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="!data.cliente?.integracaoSoc" class="field mt-1 mb-0" style="text-align: center">
                                {{ 'Sem integração' }}
                            </div>
                            <div v-if="data.cliente?.integracaoSoc && !data.codigoSoc" class="field mt-1 mb-0" style="text-align: center">
                                <i
                                    v-tooltip="'Cadastro sem codigo do SOC'"
                                    class="pi pi-exclamation-circle"
                                    style="font-size: 2rem; color: #ff9900; cursor: pointer"
                                    @click="logs(data)"
                                />
                            </div>
                            <div v-if="data.cliente?.integracaoSoc && data.codigoSoc" class="field mt-1 mb-0" style="text-align: center">
                                {{ data.codigoSoc }}
                            </div>
                        </template>
                    </Column>
                </template>
                <template v-slot:expansion="slotProps">
                    <div class="m-4 expander">
                        <div class="grid">
                            <div class="col-3">
                                <strong class="block my-2">Orientação</strong>
                                {{ slotProps.data.orientation }}
                            </div>
                            <div class="col-3">
                                <strong class="block my-2">Codigo do Soc</strong>
                                {{ slotProps.data.codigoSoc }}
                            </div>
                            <div class="col-3">
                                <strong class="block my-2">Criado em</strong>
                                {{ $filters.formatDate(slotProps.data.createdAt) }}
                            </div>
                            <div class="col-3">
                                <strong class="block my-2">Alterado em</strong>
                                {{ $filters.formatDate(slotProps.data.updatedAt) }}
                            </div>
                        </div>
                    </div>
                </template>
            </AppPaginatedGrid>
            <AppLogErroDialog
                v-if="showLogsDialog"
                v-model:visible="showLogsDialog"
                title="Logs para o cargo"
                origem="CADASTRO_CARGO"
                v-model:origemId="record.id"
                @onClose="showLogsDialog = false"
            >
            </AppLogErroDialog>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null,
            showLogsDialog: false
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/customer/positions');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        async logs(data) {
            this.record = data;
            this.showLogsDialog = true;
        }
    }
};
</script>
